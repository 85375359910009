import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/Instagram.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/icons-material/KeyboardArrowRight.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/@mui/icons-material/LinkedIn.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Accordion/Accordion.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionDetails/AccordionDetails.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/AccordionSummary/AccordionSummary.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/CardMedia/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Divider/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/List/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/ListItem/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/SvgIcon/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Banners/TopBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Footer/FacebookIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Footer/FooterLanguageChoose.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Layout/Header/Header.tsx");
